import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppRenderer = _resolveComponent("AppRenderer")!

  return ($setup.refApp && $setup.refUserId.length>0 && $setup.refAppId.length>0)
    ? (_openBlock(), _createBlock(_component_AppRenderer, {
        key: 0,
        app: $setup.refApp,
        userId: $setup.refUserId,
        appId: $setup.refAppId
      }, null, 8, ["app", "userId", "appId"]))
    : _createCommentVNode("", true)
}