<script lang="ts">
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import { PageNode } from '@/components/page/pageNode/PageNode';

import { App } from '../../App';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { BaseItem } from '@/components/shared/model/BaseItem';
import AppBarStyle from '@/components/app/appBar/view/default/Style';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PaletteColor } from '@/components/shared/theme/PaletteSet';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import { getPathForPage } from '@/Router';
import { Page } from '@/components/page/page/Page';
import Logger, { MessageLevel, MessageType } from "@/shared/Logger";
library.add(faXmark);

// variable
export default {
  name: 'AppDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    BlockContainer,
    ComponentWrapper,
    FontAwesomeIcon
},

  setup(props: any){
    const { getRefItemDeep, getRefContext } = useAddReactivity();
    const refApp = getRefItemDeep<App>(props);
    const refContext = getRefContext(props);

    //////////////////////////
    const refPageId = ref("");

    const refPageNode = ref(null) as Ref<BaseItem|null>;

    const route = useRoute();
    watch(
      () => route.params.pageId,
      () => {
        Logger.log(MessageLevel.Info, MessageType.Network, "App route params: " + JSON.stringify(route.params));
        let pageUrlOrId = route.params.pageId as string;
        // Home is the first one
        let newPageNode : PageNode | undefined = undefined;
        if (pageUrlOrId == undefined){
          console.log("No page -> Home");
          newPageNode = refApp.getHomePageNode();
        } else {
          newPageNode = refApp.getPageNode(pageUrlOrId, refContext.value.languageOption);
        }
        // ID/URL not found
        if (newPageNode == undefined){
          console.log("Wrong page -> Error page = Home");
          newPageNode = refApp.getHomePageNode();
        }

        refPageId.value = pageUrlOrId;
        refPageNode.value = newPageNode;

        /* eslint-disable vue/no-mutating-props */
        const pageRef = (refPageNode.value as PageNode).pageRef;
        refContext.value.selection.pageRef = pageRef;
        /* eslint-enable vue/no-mutating-props */

        // Load page
        const {getPage} = useServerDelegate();
        getPage(
          refContext.value.selection.userId,
          refContext.value.selection.pageRef.refId,
          (value)=>{
            console.log();
            Logger.log(MessageLevel.Info, MessageType.Context, "Page refId = " + pageRef.refId);

            const startTime = performance.now();
            try {
              const page = new Page;
              page.fromStoreObject(value);
              // Wait for the next tick, so that refContext.value.selection.page can be finalized
              setTimeout(() => {
                refContext.value.remotePageStoreObject = value;
                refContext.value.selection.page = page;
              }, 0);
            } catch (e: unknown){
              console.log(pageRef.refId, e);
            }
            const endTime = performance.now();
            const duration = endTime - startTime;
            Logger.log(MessageLevel.Info, MessageType.Storage, `page.fromStoreObject took ${duration} milliseconds.`);
            // refContext.value.selection.page = null;
          },
          (error)=>{
            console.error('Error:', error);
            refContext.value.selection.page = null;
          }
        );
      },
      { immediate: true }
    );

    refContext.value.watchQuery();

    watch(
      ()=>[
            refContext.value.selection.app!.theme.fontHeader,
            refContext.value.selection.app!.theme.fontParagraph,
            refContext.value.selection.app!.theme.fontButton,

            refContext.value.selection.app!.theme.fontHeaderWeight,
            refContext.value.selection.app!.theme.fontParagraphWeight,
            refContext.value.selection.app!.theme.fontButtonWeight,
          ],
      ()=>{
        const root = document.querySelector(':root') as HTMLElement;
        root.style.setProperty('--font-h', refContext.value.selection.app!.theme.fontHeader);
        root.style.setProperty('--font-p', refContext.value.selection.app!.theme.fontParagraph);
        // root.style.setProperty('--font-button', refContext.value.selection.app!.theme.fontButton);

        root.style.setProperty('--font-weight-h', refContext.value.selection.app!.theme.fontHeaderWeight);
        root.style.setProperty('--font-weight-p', refContext.value.selection.app!.theme.fontParagraphWeight);
        // root.style.setProperty('--font-weight-button', refContext.value.selection.app!.theme.fontButtonWeight);
      },
      {immediate: true}
    )

    const refPageTop = ref('0px');
    const refAppBarPosition = ref('absolute');
    let refStyleAppBar = ref(refApp.appBar.getStyle(refContext.value.selection.app!.theme)) as Ref<AppBarStyle>;
    watch(
      ()=>[refApp],
      ()=>{
        refStyleAppBar.value = refApp.appBar.getStyle(refContext.value.selection.app!.theme) as AppBarStyle;
      },
      {immediate: true, deep: true}
    );
    watch(
      ()=>[
        props.item, refStyleAppBar, refContext.value.mode
      ],
      ()=>{
        const displayMode = refStyleAppBar.value.displayMode.getValue();
        let pageTopPx = 0;
        switch( displayMode ){
          case "Hovering":
            if (!refContext.value.mode.inAnyEditMode()){
              pageTopPx = 0;
              refContext.value.displayOption.pageSafeAreaInsetTop = 48;
            } else {
              pageTopPx = 48;
              refContext.value.displayOption.pageSafeAreaInsetTop = 0;
            }
            break;
          case "On Top":
          default:
            pageTopPx = 48;
            refContext.value.displayOption.pageSafeAreaInsetTop = 0;
            break;
        }
        refPageTop.value = pageTopPx + 'px';

        const stickyMode = refStyleAppBar.value.stickyMode.getValue();
        switch( stickyMode ){
          case "Sticky":
            refAppBarPosition.value = "fixed";
            break;
          case "Non sticky":
            refAppBarPosition.value = "absolute";
            break;
          default:
            refAppBarPosition.value = "absolute";
        }
      },
      {immediate: true, deep: true}
    );

    onMounted(() => {
      refContext.value.selection.app!.theme.paletteNameActive = window.matchMedia('(prefers-color-scheme: dark)').matches  ? "Dark" : "Light";
      // Listen to system dark/light mode
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        refContext.value.selection.app!.theme.paletteNameActive = e.matches ? "Dark" : "Light";
      });
    });

    //////////////////////////
    const refShowMenuPopup = ref(false);

    watch(
      () => refContext.value.uiState.getLastRequestMenuPopup(),
      () => {
        refShowMenuPopup.value = refContext.value.uiState.getLastRequestMenuPopup().show;
      },
    );

    //////////////////////////
    const getButtonStyle = ()=>{
      return {
        ...refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value),
        fontFamily: refContext.value.selection.app!.theme.fontButton,
        fontWeight: refContext.value.selection.app!.theme.fontButtonWeight
      };
    }

    const getCancelButtonStyle = ()=>{
      const font = refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value);
      return {
        fontSize: font.fontSize,
        color: refStyleAppBar.value.font.color.getColor(refContext.value)
      };
    }

    return {
      refApp,
      refContext,
      refPageId,
      refPageNode,
      refStyleAppBar,
      refPageTop,
      refAppBarPosition,
      refShowMenuPopup,
      getButtonStyle,
      getCancelButtonStyle,

      PaletteColor,

      getPathForPage
    };
  }
}
</script>

<template>
  <ComponentContainer :item="refApp" :context="refContext">
    <template v-slot:body>
      <div class="App" :style="{backgroundColor: refContext.selection.app!.theme.getPaletteColor(PaletteColor.B1).getValue()}">
        <div class="App-Page" style="position: absolute; z-index: 0; bottom: 0; left: 0; right: 0;" :style="{top: refPageTop}">
          <ComponentWrapper v-if="refContext.selection.page" :item="refContext.selection.page" :context="refContext" :key="refPageId"/>
        </div>
        <div class="App-Bar" :style="{position: refAppBarPosition}" style="z-index: 1;">
          <BlockContainer :item="refApp.appBar" :context="refContext">
            <template v-slot:body>
              <ComponentWrapper :item="refApp.appBar" :context="refContext"/>
            </template>
          </BlockContainer>
        </div>
      </div>
    </template>
  </ComponentContainer>

  <!-- Menu popup -->
  <div v-if="refShowMenuPopup" class="App-MenuPopup ZIndexMenuPopup Unselectable">
    <div class="InheritSize" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 20px;"
    :style="{...refStyleAppBar.contained.getBackgroundFillPredefinedStyleObject(refContext)}">
      <router-link class="GlobalLink" :style="getButtonStyle()"
      v-for="pageNode in refContext.selection.app.pageNodes.getElements()" :key="pageNode.ref"
      :to="{path: getPathForPage(pageNode.url.getValue(), refContext), query: $route.query}"
      @click="refContext.uiState.requestMenuPopup(false);">
        {{pageNode.titleNavigation.getValueOf(refContext.languageOption)}}
      </router-link>
      <button class="GlobalFontIconButton" :style="getCancelButtonStyle()" @click="refContext.uiState.requestMenuPopup(false);">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
  </div>
</template>

<style scoped>
  .App{
    position: absolute; top: 0; bottom: 0; left: 0; right: 0;
    overflow-x: hidden;
  }

  .App::-webkit-scrollbar {
    display: none;
  }

  .App-Page{
    overflow: none;
  }

  .App-Bar{
    top: 0;
    height: 48px;
    left: 0;
    right: 0;
  }

  .App-MenuPopup {
    position: absolute; top: 0; left: 0;
    width: 100vw;
    height: 100%; height: 100dvh; /* Fallback is 100% */

    -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
  }

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
</style>