import {createRouter, createWebHistory, useRoute} from 'vue-router'

import AppRendererDelegate from './appRenderer/view/AppRendererDelegate.vue';
import { defineAsyncComponent } from 'vue';
import Context from './components/shared/view/Context';

const routes = [
  {
    path: '/',
    component: AppRendererDelegate
  },
  {
    path: '/:pageId',
    component: AppRendererDelegate
  },
  {
    path: '/:pageId/editor',
    component: defineAsyncComponent({
      loader: () => import('./appEditor/view/AppEditorDelegate.vue'),
    })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;

export const getPathForPage = (pageId: string, context: Context) : string => {
  if (pageId === undefined) return ""; //TODO: improve

  // Internal link starts with '/'
  const n = pageId.lastIndexOf('/');
  pageId = pageId.substring(n + 1);

  return `/${pageId}/${context.basePath}`;
}

export const ifInternal = (url: string) => {
  return url.startsWith("/");
};