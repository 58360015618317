<script lang="ts">
import AppViewer from '../../components/app/app/view/default/Viewer.vue';
import Context, { DisplayOption, Mode, Selection } from '../../components/shared/view/Context'
import { PropType, reactive, Ref, toRef } from 'vue';
import { App } from '@/components/app/app/App';
import { LanguageOption } from '@/components/shared/view/LanguageOption';

export default {
  props: {
    app: {
      type: Object as PropType<App>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    appId: {
      type: String,
      required: true,
    }
  },

  components: {
    AppViewer,
  },

  setup(props: any) {
    const refApp = toRef(props, 'app') as Ref<App>;

    const enableEditorInit = false;
    const rendererContext = reactive(new Context(new Mode(false, enableEditorInit, enableEditorInit, enableEditorInit), new LanguageOption(), new DisplayOption(1), new Selection(props.app, null, null)));
    rendererContext.basePath = '';
    rendererContext.selection.appId = "appId";
    rendererContext.selection.userId = String(props.userId);
    rendererContext.selection.appId = String(props.appId);

    return {
      refApp,
      rendererContext,
    }
  }
}
</script>

<template>
  <AppViewer :item="refApp" :context="rendererContext"/>
</template>

<style scoped>
</style>