import { useServerDelegate } from '@/shared/UseServerDelegate';
import Logger, { MessageLevel, MessageType } from '@/shared/Logger';
import { App } from '@/components/app/app/App';


export function useSubdomainToApp() {
  const getAppFromSubdomain = async (): Promise<any> => {
    let subdomain = '';
    let userId = '';
    let appId = '';
    const hostnameParts = window.location.hostname.split('.');
    if (hostnameParts.length > 1) {
      const { getUserSubdomainInfo, getApp } = useServerDelegate();

      subdomain = hostnameParts[0];
      Logger.log(MessageLevel.Info, MessageType.Network, `User subdomain: ${subdomain}`);

      return new Promise((resolve, reject) => {
        getUserSubdomainInfo(
          subdomain,
          (value: any) => {
            console.log('Domain info:', value);
            userId = value.UserId;
            appId = value.AppId;
            console.log('rendererContext.selection.userId:', userId);
            console.log('rendererContext.selection.appId:', appId);
            getApp(
              userId,
              appId,
              (appValue) => {
                const app = new App();
                app.fromStoreObject(appValue);
                resolve([app, userId, appId]);
              },
              (error) => {
                console.error('Error:', error);
                reject(error);
              }
            );
          },
          (error) => {
            console.error('Error:', error);
            reject(error);
          }
        );
      });
    } else {
      return Promise.reject(new Error('Invalid hostname'));
    }
  };

  return {
    getAppFromSubdomain,
  };
}