<script lang="ts">
import { onMounted, Ref, ref } from 'vue';
import AppRenderer from './AppRenderer.vue';
import { App } from '@/components/app/app/App';
import { useSubdomainToApp } from '@/shared/UseSubdomainToApp';

export default {
  name: 'AppRendererDelegate',
  components: {
    AppRenderer,
  },
  setup(){
    const refUserId = ref("");
    const refAppId = ref("");
    const refApp = ref(null) as Ref<null|App>;

    const {getAppFromSubdomain} = useSubdomainToApp();

    onMounted(async () => {
      try {
        const result = await getAppFromSubdomain();
        if (result) {
          [refApp.value, refUserId.value, refAppId.value] = result;
        }
      } catch (error) {
        console.error('Error fetching app from subdomain:', error);
      }
    });

    return {
      refApp,
      refAppId,
      refUserId
    };
  },
}
</script>

<template>
  <AppRenderer v-if="refApp && refUserId.length>0 && refAppId.length>0" :app="refApp" :userId="refUserId" :appId="refAppId"/>
  <!-- <p v-else> App with id = {{refAppId}} not found </p> -->
</template>

<style scoped>
</style>